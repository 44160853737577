import { useTranslation } from 'react-i18next';
import type { ComponentType, FC, ReactNode } from 'react';
import React, { useCallback, useMemo } from 'react';
import type { FilterDefinition } from '@pflegenavi/web-components';
import {
  Iconify,
  Label,
  makeConfig,
  OneLineTypography,
  PaginatedTable2,
  Tooltip,
  TransactionTypeLogo,
  TruncatedTooltipText,
} from '@pflegenavi/web-components';
import { IconButton, Stack, Typography } from '@mui/material';
import {
  useFormatDate,
  useFormatting,
} from '@pflegenavi/frontend/localization';
import { YEAR_MONTH_DAY_SHORT_FORMAT } from '@pflegenavi/shared/constants';
import { useTheme } from '@mui/material/styles';
import type { ReceiptBatchPaginatedDto } from '@pflegenavi/shared/api';
import { FeatureFlag, PaymentStatus } from '@pflegenavi/shared/api';
import type { UseTrackStructuredEvent } from '@pflegenavi/frontend/tracking';
import { useNavigate } from 'react-router-dom';
import { nhAppMainPages } from '@pflegenavi/frontend/routing';
import { useFeatureFlagEnabled } from '@pflegenavi/frontend/feature-flags';
import { useGetBatchReceiptDateString } from '@pflegenavi/shared/frontend';
import RefundBlueSvg from '../assets/refund-blue.svg';

type ColumnNames =
  | 'serviceProvider'
  | 'receiptInfo'
  | 'amount'
  | 'receiptImage'
  | 'status'
  | 'paymentStatus'
  | 'submissionDate';
type Filters = 'receiptDate' | 'receiptCount' | 'amount' | 'submissionDate';
export type ReceiptBatchTableFilters = 'receiptDate' | 'submissionDate';

export enum ReceiptBatchStatus {
  Pending = 'Pending',
  Submitted = 'Submitted',
}

interface ReceiptBatchOverviewTableViewProps {
  data: ReceiptBatchPaginatedDto[];
  query: string;
  onChangeQuery: (query: string) => void;
  receiptTypeId?: string;
  setReceiptTypeId: (receiptTypeId?: string) => void;
  totalRowCount: number;
  page: number;
  pageSize: number;
  onChangePage: (page: number) => void;
  onChangePageSize: (pageSize: number) => void;
  isLoading: boolean;
  DropdownComponent: ComponentType<{
    receiptTypeId?: string;
    onChangeReceiptType: (receiptTypeId?: string) => void;
  }> | null;
  onClickReceiptIcon: ({
    id,
    receiptImageIds,
    title,
    generateReceipts,
  }: {
    id: string;
    receiptImageIds: string[] | undefined;
    title: string;
    generateReceipts: boolean;
  }) => void;
  filters: ReceiptBatchTableFilters[];
  // Filters
  receiptDateRange?: {
    from?: Date;
    to?: Date;
  };
  onChangeReceiptDateRange: (range?: { from?: Date; to?: Date }) => void;
  submissionDateRange?: {
    from?: Date;
    to?: Date;
  };
  onChangeSubmissionDateRange: (range?: { from?: Date; to?: Date }) => void;
  amountRange?: {
    from?: number;
    to?: number;
  };
  onChangeAmountRange: (range?: { from?: number; to?: number }) => void;
  receiptCountRange?: {
    from?: number;
    to?: number;
  };
  onChangeReceiptCountRange: (range?: { from?: number; to?: number }) => void;
  tab: ReceiptBatchStatus | 'all';
  setTab: (tab: ReceiptBatchStatus | 'all') => void;
  useTrackStructuredEvent?: UseTrackStructuredEvent;
}

export interface ReceiptBatchRowModel {
  id: string;
  generateReceipts: boolean;
  serviceProviderName: string;
  receiptTypeName: string;
  receiptTypeIcon: ReactNode;
  receiptDateString: string;
  receiptCountString: number;
  amountString: string;
  hasImage: boolean;
  status: ReceiptBatchStatus;
  statusString: string;
  paymentStatus: 'Unknown' | PaymentStatus | 'Offline';
  submissionDateString?: string;
  onClickReceiptIcon: ({
    id,
    receiptImageIds,
    title,
    generateReceipts,
  }: {
    id: string;
    receiptImageIds: string[] | undefined;
    title: string;
    generateReceipts: boolean;
  }) => void;
  batchImageIds?: string[];
  submissionLocked?: boolean;
}

export const ReceiptBatchOverviewTableView: FC<
  ReceiptBatchOverviewTableViewProps
> = ({
  data,
  query,
  onChangeQuery,
  isLoading,
  setReceiptTypeId,
  receiptTypeId,
  onChangePageSize,
  page,
  pageSize,
  onChangePage,
  totalRowCount,
  DropdownComponent,
  filters,
  onChangeReceiptDateRange,
  receiptDateRange,
  submissionDateRange,
  onChangeSubmissionDateRange,
  amountRange,
  onChangeAmountRange,
  receiptCountRange,
  onChangeReceiptCountRange,
  onClickReceiptIcon,
  tab,
  setTab,
  useTrackStructuredEvent,
}) => {
  const { t } = useTranslation();
  const { fCurrencyCents } = useFormatting();
  const fDate = useFormatDate();
  const navigate = useNavigate();
  const isServiceProviderPaymentFeatureEnabled = useFeatureFlagEnabled(
    FeatureFlag.ServiceProviderPayments
  );

  const { getBatchReceiptDateString } = useGetBatchReceiptDateString();

  const transformed = useMemo(() => {
    // eslint-disable-next-line complexity
    return data.map<ReceiptBatchRowModel>((row) => {
      const status =
        row.state === null
          ? ReceiptBatchStatus.Pending
          : ReceiptBatchStatus.Submitted;

      const paymentStatus = row.cashListTransactionGroupId
        ? 'Offline'
        : row.payment?.status ?? 'Unknown';

      return {
        id: row.id,
        status,
        statusString: t(
          row.submissionLocked
            ? 'receipts.batch.states.locked'
            : `receipts.batch.overview-table.tabs.${status.toLowerCase()}`
        ),
        paymentStatus,
        generateReceipts: row.generateReceipts ?? false,
        submissionLocked: row.submissionLocked,
        receiptCountString: t('receipts.batch.overview-table.receipts-count', {
          count: row.numberOfReceipts,
        }),
        amountString: fCurrencyCents(row.totalAmount),
        receiptDateString: getBatchReceiptDateString(
          row.useIndividualReceiptDates ?? false,
          row.minReceiptDate,
          row.maxReceiptDate,
          row.receiptDate ?? new Date()
        ),
        hasImage: row.receiptImageIds ? row.receiptImageIds.length > 0 : false,
        serviceProviderName: row.serviceProviderInfo?.name ?? '--',
        submissionDateString: row.submissionDate
          ? fDate(row.submissionDate, YEAR_MONTH_DAY_SHORT_FORMAT)
          : '--',
        receiptTypeName: row.receiptTypeInfo?.name ?? '--',
        receiptTypeIcon: (
          <TransactionTypeLogo
            logoType={row.receiptTypeInfo?.name}
            size={48}
            additionalSubIcon={row.recurringItemId ? RefundBlueSvg : undefined}
          />
        ),
        onClickReceiptIcon,
        batchImageIds: row.receiptImageIds,
      };
    });
  }, [
    data,
    t,
    fCurrencyCents,
    getBatchReceiptDateString,
    fDate,
    onClickReceiptIcon,
  ]);

  const filtersConfig: Array<FilterDefinition<ReceiptBatchTableFilters>> =
    useMemo(() => {
      return [
        {
          key: 'receiptDate',
          type: 'dateRange',
          label: t('receipts.batch.overview-table.filters.receiptDate'),
          range: receiptDateRange,
          onChange: onChangeReceiptDateRange,
          isSet: () => !!receiptDateRange?.from || !!receiptDateRange?.to,
        },
        {
          key: 'submissionDate',
          type: 'dateRange',
          label: t('receipts.batch.overview-table.filters.submissionDate'),
          range: submissionDateRange,
          onChange: onChangeSubmissionDateRange,
          isSet: () => !!submissionDateRange?.from || !!submissionDateRange?.to,
        },
      ];
    }, [
      onChangeReceiptDateRange,
      onChangeSubmissionDateRange,
      receiptDateRange,
      submissionDateRange,
      t,
    ]);

  const config = useMemo(
    () =>
      makeConfig<ReceiptBatchRowModel, ColumnNames, Filters>({
        showAll: undefined,
        onClickRow: (row) => {
          navigate(
            nhAppMainPages.RECEIPTS_BATCH_EDIT.replace(':batchId', row.id)
          );
        },
        tabs: {
          selectedItem: tab,
          updateFilterModel: setTab,
          items: [
            {
              value: 'all',
              label: t('receipts.batch.overview-table.tabs.all'),
              color: 'default',
              count: undefined,
            },
            {
              value: ReceiptBatchStatus.Pending,
              label: t('receipts.batch.overview-table.tabs.pending'),
              color: 'default',
              count: undefined,
            },

            {
              value: ReceiptBatchStatus.Submitted,
              label: t('receipts.batch.overview-table.tabs.submitted'),
              color: 'success',
              count: undefined,
            },
          ],
        },
        columns: [
          {
            key: 'serviceProvider',
            label: t('receipts.batch.overview-table.columns.service-provider'),
            align: 'left',
            component: ServiceProviderColumn,
          },
          {
            key: 'receiptInfo',
            label: t('receipts.batch.overview-table.columns.receipts'),
            align: 'left',
            component: ReceiptInfoColumn,
          },
          {
            key: 'amount',
            label: t('receipts.batch.overview-table.columns.amount'),
            align: 'right',
            component: ({ row }) => {
              return (
                <Typography variant="subtitle2">{row.amountString}</Typography>
              );
            },
          },
          {
            key: 'receiptImage',
            label: t('receipts.batch.overview-table.columns.receiptImage'),
            align: 'center',
            component: ReceiptIconColumn,
          },
          {
            key: 'status',
            label: t('receipts.batch.overview-table.columns.status'),
            align: 'left',
            component: (props) => (
              <Stack
                direction="row"
                justifyContent="flex-start"
                gap={1}
                alignItems="center"
                className={`receiptBatchRowStatus${props.row.status}`}
              >
                <StatusColumn {...props} />
                {isServiceProviderPaymentFeatureEnabled ? (
                  <PaymentStatusColumn {...props} />
                ) : null}
              </Stack>
            ),
          },
          {
            key: 'submissionDate',
            label: t('receipts.batch.overview-table.columns.submissionDate'),
            align: 'left',
            component: ({ row }) => {
              return (
                <Typography variant="subtitle2">
                  {row.submissionDateString}
                </Typography>
              );
            },
          },
        ],
        toolbar: {
          searchBar: {
            label: t('receipts.batch.overview-table.search-bar.placeholder'),
            query: query,
            onQueryChange: onChangeQuery,
          },
          primarySelection: DropdownComponent
            ? {
                component: ({ onChange }) => (
                  <DropdownComponent
                    onChangeReceiptType={onChange}
                    receiptTypeId={receiptTypeId}
                  />
                ),
                onChange: setReceiptTypeId,
              }
            : undefined,
        },
        filters:
          filters.length > 0
            ? {
                activeFilters: 0,
                items: filtersConfig.filter((filter) =>
                  filters.includes(filter.key)
                ),
              }
            : undefined,
        pagination: {
          page,
          pageSize,
          pageSizeOptions: [5, 10, 15, 20, 25],
          total: totalRowCount,
          onPageChange: onChangePage,
          onPageSizeChange: onChangePageSize,
        },
      }),
    [
      DropdownComponent,
      filters,
      filtersConfig,
      isServiceProviderPaymentFeatureEnabled,
      navigate,
      onChangePage,
      onChangePageSize,
      onChangeQuery,
      page,
      pageSize,
      query,
      receiptTypeId,
      setReceiptTypeId,
      setTab,
      t,
      tab,
      totalRowCount,
    ]
  );

  return (
    <PaginatedTable2
      name="ReceiptBatchOverviewTable"
      useTrackStructuredEvent={useTrackStructuredEvent}
      config={config}
      rows={transformed}
      isLoading={isLoading}
    />
  );
};

const ServiceProviderColumn: React.FC<{
  row: ReceiptBatchRowModel;
}> = ({ row }) => {
  return (
    <Stack direction="row" gap={1.5} alignItems="center">
      {row.receiptTypeIcon}
      <Stack justifyContent="center" gap={0.5} maxWidth={180}>
        <TruncatedTooltipText
          text={row.serviceProviderName}
          variant="subtitle2"
          fontWeight={700}
          maxWidth={180}
        />
        <OneLineTypography variant="subtitle2" color="text.secondary">
          {row.receiptTypeName}
        </OneLineTypography>
      </Stack>
    </Stack>
  );
};

const ReceiptInfoColumn: React.FC<{
  row: ReceiptBatchRowModel;
}> = ({ row }) => {
  return (
    <Stack justifyContent="center" gap={0.5}>
      <OneLineTypography variant="subtitle2" fontWeight={700}>
        {row.receiptDateString}
      </OneLineTypography>
      <OneLineTypography variant="subtitle2" color="text.secondary">
        {row.receiptCountString}
      </OneLineTypography>
    </Stack>
  );
};

const StatusColumn: React.FC<{
  row: ReceiptBatchRowModel;
}> = ({ row }) => {
  const color = useMemo(() => {
    if (row.submissionLocked) {
      return 'warning';
    }
    switch (row.status) {
      case ReceiptBatchStatus.Pending:
        return 'default';
      case ReceiptBatchStatus.Submitted:
        return 'success';
      default:
        return 'default';
    }
  }, [row.status, row.submissionLocked]);
  return (
    <Label variant="ghost" color={color}>
      {row.statusString}
    </Label>
  );
};

const PaymentStatusColumn: React.FC<{
  row: ReceiptBatchRowModel;
}> = ({ row }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  // eslint-disable-next-line complexity
  const color = useMemo(() => {
    if (row.paymentStatus === 'Offline') {
      return theme.palette.success.light;
    }

    switch (row.paymentStatus) {
      case PaymentStatus.Pending:
        return theme.palette.info.main;
      case PaymentStatus.Completed:
        return theme.palette.success.main;
      case PaymentStatus.Failed:
        return theme.palette.error.main;
      case PaymentStatus.Disputed:
        return theme.palette.error.main;
      default:
        return theme.palette.grey['600'];
    }
  }, [row.paymentStatus, theme]);

  if (row.paymentStatus === 'Unknown') {
    return null;
  }
  return (
    <Tooltip
      title={t(
        `receipts.batch.overview-table.payment-status.${row.paymentStatus}`
      )}
    >
      <Stack justifyContent="center" flexDirection="row" alignContent="center">
        <Iconify
          icon="majesticons:euro-circle"
          color={color}
          width={18}
          height={18}
        />
      </Stack>
    </Tooltip>
  );
};

const ReceiptIconColumn: React.FC<{
  row: ReceiptBatchRowModel;
}> = ({ row }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();
      row.onClickReceiptIcon({
        id: row.id,
        receiptImageIds: row.id ? row.batchImageIds : undefined,
        title: `${row.serviceProviderName} - ${row.receiptDateString} - ${row.amountString}`,
        generateReceipts: row.generateReceipts,
      });
    },
    [row]
  );

  // eslint-disable-next-line complexity
  const { tooltip, icon, color } = useMemo(() => {
    const tooltip =
      row.hasImage || row.generateReceipts
        ? t('receipts.show-receipt-image')
        : t('tooltip.without-image');
    const icon =
      row.hasImage || row.generateReceipts
        ? 'mdi:receipt-text-check'
        : 'mdi:receipt-text-remove';
    const color = row.hasImage
      ? theme.palette.success.main
      : row.generateReceipts
      ? theme.palette.info.main
      : theme.palette.grey['400'];
    return {
      tooltip,
      icon,
      color,
    };
  }, [
    row.generateReceipts,
    row.hasImage,
    t,
    theme.palette.grey,
    theme.palette.info.main,
    theme.palette.success.main,
  ]);
  return (
    <Tooltip title={tooltip}>
      <IconButton onClick={handleClick}>
        <Iconify icon={icon} color={color} fontSize={28} />
      </IconButton>
    </Tooltip>
  );
};
